import { Center } from '@/common/components/Display/Center';
import { Group } from '@/common/components/Display/Group';
import { Stack } from '@/common/components/Display/Stack';
import { Drawer } from '@/common/components/Drawer';
import { ExternalLinkIcon } from '@/common/components/Icons/ExternalLinkIcon';
import { Text } from '@/common/components/Typography/Text';
import { SiteMenu } from '@/common/models/site-menu/SiteMenu';
import { SiteHeaderImage } from '@/front/components/site/SiteHost/SiteHeader/SiteHeaderImage';
import {
  useFrontPage,
  useFrontSite
} from '@/front/components/site/SiteHost/Store';
import { useKomoNavColorVars } from '@/front/hooks/useKomoNavColorVars';

import { MenuAnchor } from '../MenuAnchor/MenuAnchor';

interface Props {
  menu: SiteMenu;
  open: boolean;
  onClose: () => void;
}

export const MenuDrawer = ({ menu, open, onClose }: Props) => {
  const site = useFrontSite((x) => x.site);
  const page = useFrontPage((x) => x.page);
  const properties = page?.properties ?? site.properties;

  const {
    HeaderLogoHeight,
    HeaderLogoEnabled,
    BrandImage,
    HeaderBackgroundColor,
    HeaderTextColor
  } = properties;

  const navVars = useKomoNavColorVars({
    backgroundColor: HeaderBackgroundColor,
    textColor: HeaderTextColor,
    textActiveColor: page?.properties.HeaderActiveTextColor
  });

  const headerImage = !HeaderLogoEnabled ? undefined : BrandImage;

  const menuItems = menu.properties.ItemOrderArray.map((id) => {
    if (!menu.items[id.toString()]) {
      return <></>;
    }

    const item = menu.items[id.toString()];
    return (
      <MenuAnchor key={id.toString()} menuItem={item}>
        <Group justify="space-between">
          <Text>{item.name}</Text>
          {item.properties.OpenInNewTab && (
            <Center>
              <ExternalLinkIcon size={18} />
            </Center>
          )}
        </Group>
      </MenuAnchor>
    );
  });

  return (
    <Drawer
      position="left"
      opened={open}
      zIndex={2222}
      closeOnClickOutside
      onClose={onClose}
      withinPortal
      transitionProps={{ duration: 200, transition: 'slide-right' }}
      overlayProps={{ opacity: 0.5, blur: 4 }}
      c="var(--komo-nav-c)"
      __vars={navVars}
      title={<SiteHeaderImage height={HeaderLogoHeight} image={headerImage} />}
      closeButtonProps={
        {
          size: 'lg',
          c: 'var(--komo-nav-c)',
          variant: 'transparent'
        } as any
      }
      styles={{
        header: {
          backgroundColor: 'var(--komo-nav-bg)'
        },
        content: {
          backgroundColor: 'var(--komo-nav-bg)'
        }
      }}
    >
      <Stack>{menuItems}</Stack>
    </Drawer>
  );
};
