import { rem, UnstyledButton } from '@mantine/core';
import { useQuery } from '@tanstack/react-query';
import { FC } from 'react';

import { Box } from '@/common/components/Display/Box';
import { Divider } from '@/common/components/Display/Divider';
import { Stack } from '@/common/components/Display/Stack';
import { ChevronRightIcon } from '@/common/components/Icons/ChevronRightIcon';
import { Image } from '@/common/components/Image';
import { SkeletonV2 } from '@/common/components/Skeleton';
import { Text } from '@/common/components/Typography/Text';
import { ImageDataModel } from '@/common/models/ImageDataModel';
import { truncateString } from '@/common/utils/StringFunctions';
import { useUser } from '@/front/components/site/UserContext';
import { PublicContactsService } from '@/front/data/Contacts';

import classes from './_ContactProfileHeaderButton.module.css';

const getAvatarSize = () => ({ width: 120, height: 120 });

interface Props {
  onClick: () => void;
  enableEdit?: boolean;
}

export const ContactProfileHeaderButton: FC<Props> = ({
  onClick,
  enableEdit
}) => {
  const contactProfileQuery = useQuery(
    PublicContactsService.cacheKeys.properties.values(),
    () => PublicContactsService.actions.properties.getValuesAsync(),
    { onError: (e) => console.error(e) }
  );

  const { data: avatarData } = PublicContactsService.hooks.avatars.useSearch();

  const { name, email } = useUser();

  return (
    <ProfileHeaderButton
      disableEdit={!enableEdit}
      isLoading={contactProfileQuery.isLoading}
      avatar={
        contactProfileQuery.data?.propertyValues.find(
          (x) => x.propertyDefinition?.name === 'avatar'
        )?.contactAvatar?.image ||
        contactProfileQuery.data?.defaultContactAvatar?.image ||
        avatarData?.items?.[0]?.image
      }
      name={name}
      email={email}
      onClick={onClick}
    />
  );
};

interface ProfileHeaderButtonProps {
  avatar?: ImageDataModel;
  name?: string;
  email?: string;
  disableEdit?: boolean;
  onClick: () => void;
  isLoading: boolean;
}

export const ProfileHeaderButton: FC<ProfileHeaderButtonProps> = ({
  avatar,
  name,
  email,
  onClick,
  isLoading,
  disableEdit
}) => {
  if (!isLoading && !name && disableEdit && !avatar?.url) {
    return null;
  }
  return (
    <UnstyledButton
      className={classes.root}
      aria-disabled={disableEdit ? 'true' : 'false'}
      onClick={() => (isLoading || disableEdit ? undefined : onClick())}
    >
      {isLoading && (
        <>
          <SkeletonV2 h={50} w={50} flex={'0 0 50px'} />
          <SkeletonV2 h={30} flex={'1 1 auto'} />
          <SkeletonV2 w={40} h={30} flex={'0 0 auto'} />
        </>
      )}
      {!isLoading && (
        <>
          {!!avatar?.url && (
            <Box miw={50} mih={50} mah={50} h={50} flex={`0 0 ${rem(50)}`}>
              <Image
                style={{ minHeight: '50px', maxHeight: '50px', height: '50px' }}
                onCalculateSize={getAvatarSize}
                image={avatar}
                objectFit={'contain'}
                aspectRatio={{ height: 1, width: 1 }}
              />
            </Box>
          )}

          <Stack flex="1 1 auto" gap={0}>
            {name ? (
              <>
                <Text fw={500} c="inherit" truncate>
                  {truncateString(name, 28)}
                </Text>
                {!!email && (
                  <Text c="inherit" opacity={0.8} truncate>
                    {truncateString(email, 28)}
                  </Text>
                )}
              </>
            ) : (
              <>
                {email ? (
                  <Text fw={500} c="inherit" truncate>
                    {truncateString(email, 28)}
                  </Text>
                ) : (
                  !disableEdit && (
                    <Text ta="right" fw={500}>
                      Edit your profile
                    </Text>
                  )
                )}
              </>
            )}
          </Stack>
          {!disableEdit && <ChevronRightIcon size={'1.25rem'} />}
        </>
      )}

      <Divider mt={'md'} color={'currentColor'} />
    </UnstyledButton>
  );
};
