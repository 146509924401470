import { useReactQuerySync } from '@/common/data/react-query';
import { Guid } from '@/common/models/Guid';
import { Site } from '@/common/models/site/Site';

import { PublicSiteCacheKeys as CacheKeys } from '../_cacheKeys';

interface Options {
  siteId: Guid;
  onSiteChange: (site: Site) => void;
}

export function usePublicSiteCacheSync({ siteId, onSiteChange }: Options) {
  useReactQuerySync({
    items: [
      {
        name: 's',
        cacheKey: CacheKeys.get(siteId),
        callback: onSiteChange
      }
    ]
  });
}
