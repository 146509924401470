import { FC } from 'react';

import { ActionButton } from '@/common/components/Button';
import { Group } from '@/common/components/Display/Group';
import { ArrowLeftIcon } from '@/common/components/Icons/ArrowLeftIcon';
import { Title } from '@/common/components/Typography/Title';

import classes from './_ProfileDrawerHeader.module.css';

interface HeaderProps {
  isEditTab: boolean;
  onClose: () => void;
}

export const ProfileHeader: FC<HeaderProps> = ({
  onClose,
  isEditTab = false
}) => {
  return (
    <Group mb={'0.5rem'} spacing={'0.5rem'} align={'center'}>
      {isEditTab && (
        <ActionButton
          size="lg"
          className={classes.button}
          onClick={onClose}
          variant={'subtle'}
        >
          <ArrowLeftIcon size={'1.25rem'} />
        </ActionButton>
      )}
      <Title order={2}>{isEditTab && 'Edit'} Profile</Title>
    </Group>
  );
};
