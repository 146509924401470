import { useMemo } from 'react';
import { FieldValues } from 'react-hook-form';

import { FieldValidator } from '@/common/components/Form/FormValidators/Validator';

import { FormHookField, FormHookFieldProps } from '../../FormHookField';
import { RequiredValidatorOptions } from '../../FormValidators';
import { DynamicFormContextData, IAmDynamicFormFieldProps } from '../types';

export interface DynamicFormHookFieldProps<
  TFieldValues extends FieldValues = FieldValues,
  TContextData extends DynamicFormContextData = DynamicFormContextData
> extends Pick<
    IAmDynamicFormFieldProps<TFieldValues, TContextData>,
    'field' | 'contextData' | 'disabled'
  > {
  extraValidators?: FieldValidator<TFieldValues, TContextData>[];
  requiredVariant?: RequiredValidatorOptions['variant'];
  children: (props: FormHookFieldProps<TFieldValues>) => JSX.Element;
}

export function DynamicFormHookField<
  TFieldValues extends FieldValues = FieldValues,
  TContextData extends DynamicFormContextData = DynamicFormContextData
>({
  field,
  contextData,
  disabled,
  extraValidators,
  requiredVariant,
  children
}: DynamicFormHookFieldProps<TFieldValues, TContextData>) {
  const { name, validators: validatorsProps, required } = field;

  const validators = useMemo(() => {
    const validators: FieldValidator<TFieldValues, TContextData>[] = [];

    (validatorsProps || [])?.forEach((v) => validators.push(v));
    (extraValidators || [])?.forEach((v) => validators.push(v));

    return validators;
  }, [validatorsProps, extraValidators]);

  return (
    <FormHookField<TFieldValues>
      name={name}
      disabled={disabled || field.disabled}
      validators={validators}
      contextData={contextData}
      required={{ enabled: required, variant: requiredVariant, trim: true }}
    >
      {children}
    </FormHookField>
  );
}
