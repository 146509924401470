import { ApiHeaders } from '@/common/data/ApiHeaders';
import { ApiRequestConfig } from '@/common/data/ApiRequestConfig';
import { Guid } from '@/common/models/Guid';
import { Site } from '@/common/models/site/Site';
import { SiteEntryConfirmationResponse } from '@/common/models/site/SiteEntryConfirmationResponse';
import { SiteUser } from '@/common/models/SiteUser';
import { ActivitySlice } from '@/common/models/user-sessions-v2/Front/ActivitySlice';
import { FrontSessionV2 } from '@/common/models/user-sessions-v2/Front/FrontSessionV2';
import { mapArray } from '@/common/utils/ArrayFunctions';
import { QueryParams } from '@/common/utils/QueryParams';
import { FrontSessionStore } from '@/front/components/site/user-session-v2/FrontSessionStore';
import { SessionV2SessionStorageHelper } from '@/front/components/site/user-session-v2/SessionV2SessionStorageHelper';
import { CookieConstants } from '@/front/utils/Cookies/CookieConstants';

import { PublicLiveApi } from '../PublicApi';
import {
  CheckEntryConditionRequest,
  CollectActivityOptions,
  GetCurrentUserSessionSsrRequest,
  InitUserSessionV2Request,
  MagicLinkRequest,
  SsoRequest,
  SsoResponse
} from './_types';

export const PublicSiteActions = {
  getByDomainAsync: async (domain: string) =>
    new Site(await PublicLiveApi.get(`sites/domain/${domain}`)),

  getByIdAsync: async (siteId: string | Guid) =>
    new Site(await PublicLiveApi.get(`sites/${siteId}`)),

  singleSignOnAsync: async (siteId: string | Guid, request: SsoRequest) =>
    new SsoResponse(await PublicLiveApi.post(`sites/${siteId}/sso`, request)),

  getSiteByCardIdAsync: async (cardId: string | Guid) =>
    new Site(await PublicLiveApi.get(`sites/by-card/${cardId}`)),

  checkEntryConditionAsync: async ({
    siteId,
    entryCondition
  }: CheckEntryConditionRequest) => {
    return new SiteEntryConfirmationResponse(
      await PublicLiveApi.post(`sites/${siteId}/check-entry`, {
        entryCondition
      })
    );
  },

  validateMagicLinkSsrAsync: async (
    { siteId, token }: MagicLinkRequest,
    cookies?: Record<string, string>
  ) => {
    const userSessionId = cookies[CookieConstants.Fingerprint.name];
    const profileId = cookies[CookieConstants.ProfileId.name];

    const headers = {} as HeadersInit;

    if (userSessionId) {
      headers[ApiHeaders.SsrFingerprintId] = userSessionId.toString();
    }

    if (profileId) {
      headers[ApiHeaders.SsrProfileId] = profileId.toString();
    }

    const config: ApiRequestConfig = {
      headers
    };
    const res = await PublicLiveApi.post(
      `sites/${siteId}/auth/validate-link${new QueryParams({
        t: token
      }).toString()}`,
      undefined,
      config
    );
    if (res) {
      return new SsoResponse(res);
    }
  },

  currentSessionAsync: async ({
    siteId,
    isBot
  }: {
    siteId: string | Guid;
    isBot?: boolean;
  }) => {
    return new SiteUser(
      await PublicLiveApi.get<SiteUser>(
        `sites/${siteId.toString()}/sessions${new QueryParams({ isBot })}`
      )
    );
  },

  currentSessionFromServerAsync: async (
    siteId: Guid,
    { isBot, profileId, userFingerprintId }: GetCurrentUserSessionSsrRequest
  ) => {
    const query = new QueryParams({
      userId: userFingerprintId,
      isBot
    }).toString();
    const url = `sites/${siteId}/sessions${query}`;

    const headers = {} as HeadersInit;

    if (userFingerprintId) {
      headers[ApiHeaders.SsrFingerprintId] = userFingerprintId.toString();
    }

    if (profileId) {
      headers[ApiHeaders.SsrProfileId] = profileId.toString();
    }

    const config: ApiRequestConfig = {
      headers
    };

    return new SiteUser(await PublicLiveApi.get(url, config));
  },

  initSessionV2Async: async ({ siteId, ...rest }: InitUserSessionV2Request) => {
    const response = await PublicLiveApi.post<FrontSessionV2>(
      `sites/${siteId}/sessions-v2/init`,
      rest
    );
    const userSession = new FrontSessionV2(response);
    FrontSessionStore.getState().setSessionV2Id(userSession.id);
  },

  tryCollectActivityAsync: async (
    siteId: Guid,
    options?: CollectActivityOptions
  ) => {
    let activityEvents = [];

    try {
      if (!FrontSessionStore.getState().session) return;

      const date = new Date();
      activityEvents = FrontSessionStore.getState().prepareForCollection(date);

      const useBeacon = options?.useBeacon && PublicLiveApi.canSendBeacon();
      if (useBeacon) {
        const beaconQueued = PublicLiveApi.sendBeacon(
          `sites/${siteId}/collect`,
          {
            t: SessionV2SessionStorageHelper.getTabId(),
            e: mapArray(activityEvents, (x: ActivitySlice) => x.compact())
          }
        );
        if (beaconQueued) {
          return;
        }
      } else {
        const response = await PublicLiveApi.post<FrontSessionV2>(
          `sites/${siteId}/collect`,
          {
            t: SessionV2SessionStorageHelper.getTabId(),
            e: mapArray(activityEvents, (x: ActivitySlice) => x.compact())
          }
        );
        const userSession = new FrontSessionV2(response);
        FrontSessionStore.getState().setSessionV2Id(
          userSession.id,
          date,
          options?.isIdle
        );

        return;
      }
    } catch (e) {
      console.error(e, 'Unable to collect activity');
    }

    // if we get here, we didn't collect successfully, so reset the activity slices
    if (activityEvents.length) {
      FrontSessionStore.getState().resetAfterFailedCollection(activityEvents);
    }
  }
};
