import { useModalsShown } from '@/common/components/Portal/DomPortalProvider';
import { useUnsafeEmbeddedHostContextSelector } from '@/front/components/embedded/EmbeddedHostProvider';

/**
 * Use an embed-aware modal overlay style.
 * If we're in a Komo-controlled iframe embed, then we dont use a modal overlay in this case
 * as we're already in a modal - instead we hide the site content.
 * Otherwise return a transparent black for non-embedded situations.
 */
export const useSiteModalOverlayStyle = () => {
  const isInKomoIframe = useUnsafeEmbeddedHostContextSelector(
    (s) => s.isInKomoIframe
  );
  const modalShownCount = useModalsShown();

  if (isInKomoIframe && modalShownCount === 1) {
    return { backgroundColor: 'transparent' };
  }

  return { backgroundColor: 'rgba(0, 0, 0, 0.9)' };
};
