import isNil from 'lodash/isNil';
import { FieldValues } from 'react-hook-form';

import { IAmFormHookField } from '../FormHookField';
import {
  FormFileInputV2,
  FormFileInputV2Props,
  resolveFormFileInputValue
} from './FormFileInputV2';

export interface FormHookFileInputV2Props<
  TFieldValues extends FieldValues = any
> extends IAmFormHookField<TFieldValues>,
    Omit<
      FormFileInputV2Props,
      | 'required'
      | 'name'
      | 'value'
      | 'defaultValue'
      | 'error'
      | 'disabled'
      | 'onDelete'
    > {}

export function FormHookFileInputV2<TFieldValues extends FieldValues = any>(
  props: FormHookFileInputV2Props<TFieldValues>
) {
  const { hook, onUploadAsync, ...rest } = props;
  return (
    <FormFileInputV2
      {...rest}
      required={hook.required}
      name={hook.field.name}
      ref={hook.field.ref}
      disabled={hook.disabled}
      onUploadAsync={async (f) => {
        const files = await onUploadAsync(f);
        hook.setFieldValue(
          JSON.stringify([
            ...resolveFormFileInputValue(hook.field.value),
            ...files
          ]) as any,
          {
            shouldValidate: true,
            shouldDirty: true
          }
        );
        return files;
      }}
      value={!isNil(hook.field.value) ? hook.field.value : ''}
      error={hook.showError ? hook.errorMessage : undefined}
      onDelete={(filePath) => {
        hook.setFieldValue(
          JSON.stringify(
            [...resolveFormFileInputValue(hook.field.value)].filter(
              (f) => f !== filePath
            )
          ) as any,
          {
            shouldValidate: true,
            shouldDirty: true
          }
        );
      }}
    />
  );
}
