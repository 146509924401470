import { FCC } from 'fcc';
import { CSSProperties } from 'react';

import { Box, BoxProps } from '@/common/components/Display/Box';

import classes from './ListItemText.module.css';

interface StyledProps extends BoxProps {}

interface Props extends StyledProps {
  style?: CSSProperties;
}
export const ListItemText: FCC<Props> = ({ children, ...rest }) => {
  return (
    <Box className={classes.root} {...rest}>
      {children}
    </Box>
  );
};
ListItemText.displayName = 'ListItemText';
