import { FC } from 'react';

import { Box } from '@/common/components/Display/Box';
import { Image } from '@/common/components/Image';
import { ImageDataModel } from '@/common/models/ImageDataModel';

interface Props {
  image?: ImageDataModel;
}

export const DynamicFormRendererBanner: FC<Props> = ({ image }) => {
  if (!image?.url) {
    return null;
  }

  return (
    <Box mih="3.5rem" pos={'relative'}>
      <div>
        <Image display="block" image={image} />
      </div>
    </Box>
  );
};
