import { FC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { Button } from '@/common/components/Button';
import { Center } from '@/common/components/Display/Center';
import { FormGroup } from '@/common/components/Form/FormGroup';
import { FormHookField } from '@/common/components/Form/FormHookField';
import { FormHookHelpers } from '@/common/components/Form/FormHookHelpers';
import { FormHookTextInput } from '@/common/components/Form/FormTextInput';
import { PadlockIcon } from '@/common/components/Icons/PadlockIcon';
import { AuthPanel } from '@/common/components/Panel/AuthPanel';
import { useToaster } from '@/common/components/Toast';
import { Page } from '@/common/models/pages/Page';
import { Site } from '@/common/models/site/Site';

interface Props {
  site: Site;
  onPasswordSuccess: (password: string) => void;
  page?: Page;
  isSingleCard?: boolean;
}

interface FormValues {
  draftPreviewPassword: string;
}

export const SiteDraftViewPasswordPage: FC<Props> = ({
  site,
  onPasswordSuccess,
  page,
  isSingleCard
}) => {
  const toaster = useToaster();
  const methods = useForm<FormValues>({
    mode: 'all',
    defaultValues: { draftPreviewPassword: '' }
  });

  const handleSubmit = FormHookHelpers.onSubmit(
    methods,
    (values: FormValues) => {
      if (
        values.draftPreviewPassword === site.properties.DraftPreviewPassword
      ) {
        onPasswordSuccess(values.draftPreviewPassword);
      } else {
        toaster.error(
          'Invalid draft preview password entered. Please try again'
        );
      }
    }
  );

  let draftType = 'Hub';
  if (site.isDraft) {
    draftType = 'Hub';
  } else if (page?.isDraft) {
    draftType = 'Page';
  } else if (isSingleCard) {
    draftType = 'Card';
  }

  return (
    <Center h="100vh">
      <AuthPanel title={draftType + ' is not published'}>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit}>
            <FormHookField<FormValues>
              name="draftPreviewPassword"
              required={{ enabled: true, trim: true }}
            >
              {(h) => (
                <FormHookTextInput
                  hook={h}
                  label="Password"
                  size="lg"
                  mb="1rem"
                  leftSection={<PadlockIcon />}
                />
              )}
            </FormHookField>
            <FormGroup>
              <Button size="lg" variant={'filled'} w="100%" type="submit">
                {`Visit ${isSingleCard ? 'card' : 'hub'}`}
              </Button>
            </FormGroup>
          </form>
        </FormProvider>
      </AuthPanel>
    </Center>
  );
};
