import { ElementProps as MantineElementProps } from '@mantine/core';
import isNil from 'lodash/isNil';
import { forwardRef } from 'react';

import { Box, BoxProps } from '@/common/components/Display/Box';
import { KeyOf } from '@/common/models/KeyOf';
import { ThemeColors } from '@/theme';

import classes from './List.module.css';
import { ListContext } from './ListContext';

export interface ListItemStyle {
  noGap?: boolean;
  noShadow?: boolean;
  textColorVariant?: KeyOf<ThemeColors> | string;
  noBackgroundColor?: boolean;
  border?: string;
  padding?: string;
}
export interface ListProps extends BoxProps, MantineElementProps<'ul'> {
  itemStyles?: ListItemStyle;
  /**
   * @deprecated use 'p' instead
   */
  noPadding?: boolean;
  selectable?: boolean;
  rounded?: boolean;
}
/**
 * @deprecated Try using ListV2
 */
export const List = forwardRef<HTMLUListElement, ListProps>(
  (
    {
      children,
      itemStyles,
      selectable = false,
      noPadding,
      rounded = false,
      p: pProp,
      ...rest
    },
    ref
  ) => {
    let p = pProp;
    if (isNil(p) && noPadding) {
      p = 0;
    }

    return (
      <ListContext.Provider
        value={{
          itemsHaveShadow: !itemStyles?.noShadow,
          itemsTextColorVariant: itemStyles?.textColorVariant,
          itemsSelectable: selectable,
          itemsHaveBackgroundColor: !itemStyles?.noBackgroundColor,
          itemsBorder: itemStyles?.border,
          itemsPadding: itemStyles?.padding
        }}
      >
        <Box
          component="ul"
          ref={ref}
          data-items-have-gap={!itemStyles?.noGap}
          data-rounded={rounded}
          data-no-padding={noPadding}
          p={p}
          className={classes.container}
          {...rest}
        >
          {children}
        </Box>
      </ListContext.Provider>
    );
  }
);
