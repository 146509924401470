import { Pagination } from '@mantine/core';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { FC, useState } from 'react';

import { BlockLoader } from '@/common/components/BlockLoader';
import { Button } from '@/common/components/Button';
import { Group } from '@/common/components/Display/Group';
import { Stack } from '@/common/components/Display/Stack';
import { FormDisplay } from '@/common/components/Form/FormDisplay';
import { Loader } from '@/common/components/Loader';
import { useActionHandler } from '@/common/hooks/useActionHandler';
import { Guid } from '@/common/models/Guid';
import { AvatarSelection } from '@/front/components/site/SiteHost/SiteHeader/Profile/Drawer/EditProfile/AvatarSelection';
import { PublicContactsService } from '@/front/data/Contacts';
import { SearchPropertyDefinitionsRequest } from '@/front/data/Contacts/_types';

interface Props {
  onBack: () => void;
  drawerTextColor?: string;
  drawerBackgroundColor?: string;
}

const propertyDefinitionsRequest: SearchPropertyDefinitionsRequest = {
  skip: 0,
  take: 50
};

export const EditProfileContent: FC<Props> = (props) => {
  const definitionsQuery = useQuery(
    PublicContactsService.cacheKeys.properties.searchDefinitions(
      propertyDefinitionsRequest
    ),
    () =>
      PublicContactsService.actions.properties.searchDefinitionsAsync(
        propertyDefinitionsRequest
      ),
    { onError: (e) => console.error(e) }
  );

  const avatarDefinitionId = definitionsQuery.data?.items?.find(
    (x) => x.name == 'avatar'
  )?.id;

  const contactProfileQuery = useQuery(
    PublicContactsService.cacheKeys.properties.values(),
    () => PublicContactsService.actions.properties.getValuesAsync(),
    { onError: (e) => console.error(e) }
  );

  const currentAvatarId =
    contactProfileQuery.data?.propertyValues.find(
      (x) => x.propertyDefinition?.name === 'avatar'
    )?.contactAvatar?.id ?? contactProfileQuery.data?.defaultContactAvatar?.id;

  const isLoading = definitionsQuery.isLoading || contactProfileQuery.isLoading;
  const isError = definitionsQuery.isError || contactProfileQuery.isError;

  return (
    <>
      {isLoading && <Loader />}
      {!isLoading && !isError && (
        <Inner
          {...props}
          avatarPropertyDefinitionId={avatarDefinitionId}
          currentAvatarId={currentAvatarId}
        />
      )}
    </>
  );
};

interface InnerProps extends Props {
  avatarPropertyDefinitionId?: Guid;
  currentAvatarId?: Guid;
}

const Inner: FC<InnerProps> = ({
  avatarPropertyDefinitionId,
  currentAvatarId,
  drawerBackgroundColor,
  drawerTextColor,
  onBack
}) => {
  const [selectedAvatarId, setSelectedAvatarId] =
    useState<Guid>(currentAvatarId);

  const { isLoading, isFetching, pagination, hasAnyData, data } =
    PublicContactsService.hooks.avatars.useSearch(
      10,
      !avatarPropertyDefinitionId
    );

  const [handler, { isHandling }] = useActionHandler();

  const queryClient = useQueryClient();
  const onSaveAsync = () =>
    handler(
      () =>
        PublicContactsService.actions.properties.updateValueAsync({
          propertyUpdates: [
            {
              propertyDefinitionId: avatarPropertyDefinitionId,
              avatarId: selectedAvatarId
            }
          ]
        }),
      {
        onSuccess: () => {
          queryClient.invalidateQueries(
            PublicContactsService.cacheKeys.achievements.getBadgeAlbumImage()
          );
          queryClient.invalidateQueries(
            PublicContactsService.cacheKeys.properties.values()
          );
          onBack();
        }
      }
    );

  return (
    <Stack>
      {hasAnyData && !!avatarPropertyDefinitionId && (
        <FormDisplay label={'Pick your avatar'} labelProps={{ c: 'inherit' }}>
          <BlockLoader isLoading={isLoading || isFetching} opacity={0.1}>
            <AvatarSelection
              avatars={data?.items || []}
              selectedId={selectedAvatarId}
              onSelected={(id) => setSelectedAvatarId(id)}
            />
          </BlockLoader>
          {pagination.totalPages > 1 && (
            <Pagination.Root
              size={'lg'}
              total={pagination.totalPages}
              value={pagination.pageNumber}
              onChange={(p) => pagination.setPage(p)}
            >
              <Group gap={5} position="center" mt={'md'}>
                <Pagination.Previous />
                <Pagination.Next />
              </Group>
            </Pagination.Root>
          )}
        </FormDisplay>
      )}
      {/*todo*/}
      {/*<FormTextInput label={'First name'} />*/}
      {/*<FormTextInput label={'Last name'} />*/}
      {/*<FormTextInput label={'Email'} />*/}
      <Button
        c={drawerTextColor}
        bg={drawerBackgroundColor}
        styles={{
          root: {
            backgroundColor: drawerBackgroundColor,
            borderColor: drawerTextColor
          },
          label: {
            color: drawerTextColor
          }
        }}
        disabled={isHandling || isLoading}
        onClick={() => onSaveAsync()}
        variant={'default'}
        size={'md'}
      >
        Save
      </Button>
    </Stack>
  );
};
