import { FieldValues } from 'react-hook-form';

import { FormHookNativeSelect } from '@/common/components/Form/FormSelect/Native';

import {
  DynamicFormContextData,
  IAmDynamicFormFieldProps,
  NativeSelectDynamicFieldOptions
} from '../types';
import { DynamicFormHookField } from './__DynamicFormHookField';

export function DynamicFormNativeSelectField<
  TFieldValues extends FieldValues = FieldValues,
  TContextData extends DynamicFormContextData = DynamicFormContextData
>({
  field,
  disabled,
  contextData,
  ...rest
}: IAmDynamicFormFieldProps<TFieldValues, TContextData>) {
  return (
    <DynamicFormHookField<TFieldValues, TContextData>
      field={field}
      disabled={disabled}
      contextData={contextData}
    >
      {(h) => (
        <FormHookNativeSelect
          hook={h}
          {...(field.data.options as NativeSelectDynamicFieldOptions)}
          {...rest}
        />
      )}
    </DynamicFormHookField>
  );
}
