import { useRouter } from 'next/router';
import React, { FC, memo, useState } from 'react';

import { Anchor } from '@/common/components/Anchor';
import { Button } from '@/common/components/Button';
import { Box } from '@/common/components/Display/Box';
import { Stack } from '@/common/components/Display/Stack';
import { HtmlRendererV2 } from '@/common/components/HtmlRenderer/HtmlRendererV2';
import { NoSsr } from '@/common/components/NoSsr';
import { Text } from '@/common/components/Typography/Text';
import { Title } from '@/common/components/Typography/Title';
import { useOnMount } from '@/common/hooks/useOnMount';
import { ConsentCookieService } from '@/common/utils/ConsentCookieService';
import { useFrontSite } from '@/front/components/site/SiteHost/Store';
import { IntegrationService } from '@/front/data/IntegrationService/IntegrationService';

import { FormCheckbox } from '../Form/FormCheckbox';
import classes from './CtaButton.module.css';

export const ConsentBanner: FC = () => {
  return (
    <NoSsr onSsr={null}>
      <Banner />
    </NoSsr>
  );
};

const Banner: FC = memo(() => {
  const site = useFrontSite((x) => x.site);
  const [shouldShow, setShouldShow] = useState(false);

  const [isManaging, setIsManaging] = useState(false);
  const [acceptNonEssential, setAcceptNonEssential] = useState(false);
  const router = useRouter();

  useOnMount(() => {
    if (!ConsentCookieService.hasSavedCookieSettings()) {
      setShouldShow(true);
    }
  });

  if (!site.properties.CookieConsentEnabled) {
    return null;
  }

  if (!shouldShow) {
    return null;
  }

  const {
    backgroundColor,
    textColor,
    buttonBackgroundColor,
    buttonTextColor,
    title,
    htmlContent
  } = site.getCookieConsentConfiguration();

  const handleAcceptAll = async () => {
    ConsentCookieService.setConsent(true);
    await IntegrationService.staticUpdateConsentAsync(true, site);
    setShouldShow(false);
    router.reload();
  };

  const handleSaveSettings = async () => {
    ConsentCookieService.setConsent(acceptNonEssential);
    await IntegrationService.staticUpdateConsentAsync(acceptNonEssential, site);
    setShouldShow(false);
    router.reload();
  };

  return (
    <Box className={classes.container} bg={backgroundColor}>
      <Stack gap={0} align={'flex-start'} w={'100%'} maw={672}>
        {title && (
          <Title order={2} c={textColor}>
            {title}
          </Title>
        )}
        <Text mb="xs" c={textColor}>
          <HtmlRendererV2 html={htmlContent} />
        </Text>
        {isManaging && (
          <Stack gap={0}>
            <FormCheckbox
              mb="0"
              withAnimation
              label={{
                type: 'Content',
                content: <span style={{ color: textColor }}>Essential</span>
              }}
              disabled
              checked
            />
            <Text mb="md" size="sm" color={textColor}>
              These cookies are essential to provide this service or other
              features.
            </Text>
            <FormCheckbox
              mb="0"
              label={{
                type: 'Content',
                content: <span style={{ color: textColor }}>Non Essential</span>
              }}
              onChange={(e) => {
                const checked = e.currentTarget.checked;
                setAcceptNonEssential(checked);
              }}
              checked={acceptNonEssential}
            />
            <Text mb="md" size="sm" color={textColor}>
              Accept cookies for performance, analytics and advertising
              information to improve and personalise your experience and help
              make this website better.
            </Text>
          </Stack>
        )}
        <div className={classes.buttonContainer}>
          {isManaging ? (
            <>
              <div />
            </>
          ) : (
            <Anchor
              onClick={() => setIsManaging(true)}
              c={textColor}
              underline="always"
            >
              Manage your preferences
            </Anchor>
          )}

          <Button
            variant="styled"
            styledVariantProps={{
              color: buttonTextColor,
              bgColor: buttonBackgroundColor
            }}
            size="md"
            onClick={isManaging ? handleSaveSettings : handleAcceptAll}
          >
            Accept
          </Button>
        </div>
      </Stack>
    </Box>
  );
});
