import {
  Input as MantineInput,
  InputWrapperProps as MantineInputWrapperProps
} from '@mantine/core';
import { FCC } from 'fcc';
import { CSSProperties } from 'react';

import { Box } from '@/common/components/Display/Box';

import {
  FormInputWrapper,
  FormInputWrapperProps
} from '../InputWrapper/FormInputWrapper';

export interface FormDisplayProps
  extends Pick<
      FormInputWrapperProps<HTMLElement>,
      | 'error'
      | 'hideErrorMessage'
      | 'inputContainer'
      | 'showSkeleton'
      | 'size'
      | 'descriptionProps'
      | 'labelProps'
    >,
    Pick<
      MantineInputWrapperProps,
      | 'label'
      | 'description'
      | 'errorProps'
      | 'm'
      | 'mt'
      | 'mb'
      | 'mx'
      | 'my'
      | 'id'
      | 'w'
      | 'maw'
      | 'p'
    > {
  containerStyle?: CSSProperties;
  dataHelpId?: string;
}

export const FormDisplay: FCC<FormDisplayProps> = ({
  dataHelpId,
  children,
  containerStyle,
  descriptionProps,
  error,
  hideErrorMessage,
  labelProps,
  inputContainer,
  showSkeleton,
  size,
  id,
  ...rest
}) => {
  return (
    <FormInputWrapper
      descriptionProps={descriptionProps}
      error={error}
      hideErrorMessage={hideErrorMessage}
      labelProps={labelProps}
      inputContainer={inputContainer}
      showSkeleton={showSkeleton}
      size={size}
    >
      {(p) => (
        <MantineInput.Wrapper
          {...p}
          w="100%"
          data-help-id={dataHelpId}
          style={containerStyle}
          {...rest}
        >
          <Box w={'100%'} id={id}>
            {children}
          </Box>
        </MantineInput.Wrapper>
      )}
    </FormInputWrapper>
  );
};
