import {
  NativeSelect as MantineNativeSelect,
  NativeSelectProps as MantineNativeSelectProps
} from '@mantine/core';
import { ForwardedRef, forwardRef } from 'react';

import { OptionModel } from '@/common/models/OptionModel';

import {
  IAmFormInputRenderProps,
  FormInputWrapper
} from '../../InputWrapper/FormInputWrapper';

export interface FormNativeSelectProps<T extends OptionModel = OptionModel>
  extends Omit<
      MantineNativeSelectProps,
      | 'labelProps'
      | 'descriptionProps'
      | 'withAsterisk'
      | 'itemComponent'
      | 'data'
      | 'size'
    >,
    IAmFormInputRenderProps<HTMLSelectElement> {
  data: T[];
}

function _FormNativeSelect<T extends OptionModel = OptionModel>(
  {
    required,
    onFocus,
    selectAllTextOnFocus,
    autoFocus,
    descriptionProps,
    inputContainer,
    showSkeleton,
    labelProps,
    error,
    hideErrorMessage,
    size,
    ...rest
  }: FormNativeSelectProps<T>,
  forwardedRef: ForwardedRef<HTMLSelectElement>
) {
  return (
    <FormInputWrapper<HTMLSelectElement>
      ref={forwardedRef}
      autoFocus={autoFocus}
      onFocus={onFocus}
      selectAllTextOnFocus={selectAllTextOnFocus}
      error={error}
      hideErrorMessage={hideErrorMessage}
      showSkeleton={showSkeleton}
      descriptionProps={descriptionProps}
      inputContainer={inputContainer}
      labelProps={labelProps}
      size={size}
    >
      {(p, ref) => (
        <MantineNativeSelect
          ref={ref}
          withAsterisk={required}
          {...rest}
          {...p}
          size={size}
        />
      )}
    </FormInputWrapper>
  );
}

export const FormNativeSelect = forwardRef(_FormNativeSelect) as <
  T extends OptionModel = OptionModel
>(
  props: FormNativeSelectProps<T> & { ref?: ForwardedRef<HTMLSelectElement> }
) => ReturnType<typeof _FormNativeSelect>;
