import { FieldValues } from 'react-hook-form';

import { asBoolean } from '@/common/utils/BooleanFunctions';

import { IAmFormHookField } from '../FormHookField';
import { FormCheckbox, FormCheckboxProps } from './FormCheckbox';

export interface FormHookCheckboxProps<
  TFieldValues extends FieldValues = FieldValues
> extends IAmFormHookField<TFieldValues>,
    Omit<
      FormCheckboxProps,
      | 'required'
      | 'name'
      | 'value'
      | 'defaultValue'
      | 'checked'
      | 'defaultChecked'
      | 'error'
      | 'disabled'
    > {}

export function FormHookCheckbox<
  TFieldValues extends FieldValues = FieldValues
>(props: FormHookCheckboxProps<TFieldValues>) {
  const { hook, onBlur, onChange, ...rest } = props;
  return (
    <FormCheckbox
      {...rest}
      required={hook.required}
      name={hook.field.name}
      ref={hook.field.ref as any}
      disabled={hook.disabled}
      onBlur={(e) => {
        hook.field.onBlur();
        onBlur?.(e);
      }}
      onChange={(e) => {
        hook.field.onChange(e);
        onChange?.(e);
      }}
      checked={asBoolean(hook.field.value) == true}
      error={hook.showError ? hook.errorMessage : undefined}
    />
  );
}
