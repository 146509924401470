import { FCC } from 'fcc';
import { Fragment, memo, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

import { PortalComponent } from './DomPortal.types';

interface Props {
  components: Record<string, PortalComponent>;

  /**
   * components will be rendered as children of this component. React.Fragment is
   * used by default.
   */
  wrapper?: FCC<any>;
}

interface RendererProps {
  component: PortalComponent;
}

/**
 * The identity of `Component` may change depending on the inputs passed to
 * `useShowPortalComponent`. If we simply rendered `<Component />` then the component would be
 * susceptible to re-renders whenever one of the inputs change.
 */
const Renderer = memo(({ component, ...rest }: RendererProps) =>
  component(rest)
);

export const PortalRoot = memo(
  ({ components, wrapper: WrapperComponent = Fragment }: Props) => {
    const [mountNode, setMountNode] = useState<Element | undefined>(undefined);

    // This effect will not be ran in the server environment
    useEffect(() => setMountNode(document.body), []);

    return (
      <>
        {mountNode
          ? ReactDOM.createPortal(
              <WrapperComponent>
                {Object.keys(components).map((key) => (
                  <Renderer key={key} component={components[key]} />
                ))}
              </WrapperComponent>,
              mountNode
            )
          : null}
      </>
    );
  }
);
