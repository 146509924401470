import { FC, useEffect } from 'react';
import {
  DEFAULT_EVENTS,
  IIdleTimerProps,
  useIdleTimer
} from 'react-idle-timer';

import { Stack } from '@/common/components/Display/Stack';
import { Title } from '@/common/components/Typography/Title';
import { useIsDebug } from '@/front/components/komo-debug/KomoDebugContext';
import { KomoDebugPortal } from '@/front/components/komo-debug/KomoDebugPortal';
import {
  IdleTimerStore,
  useIdleTimerStore
} from '@/front/components/site/user-session-v2/IdleTimerStore';

interface Props extends Omit<IIdleTimerProps, 'onPresenceChange' | 'events'> {}

// We exclude visibilitychange because we want to treat 'hidden' as idle
// https://github.com/SupremeTechnopriest/react-idle-timer/issues/265
const events = [...DEFAULT_EVENTS];
const visibilityChangeEvent = 'visibilitychange';
events.slice(events.indexOf(visibilityChangeEvent), 1);

/**
 * Not really a provider, but required to provide the idle timer updates to the store
 */
export const IdleTimerStoreSync: FC<Props> = (props) => {
  const timer = useIdleTimer({
    ...props,
    events,
    onPresenceChange: (presence) =>
      IdleTimerStore.setState({ isIdle: presence.type === 'idle' })
  });

  // manually listen to visibility change event and set idle when hidden
  useEffect(() => {
    if (!window || !document) {
      return;
    }

    if (!('onvisibilitychange' in document)) {
      return;
    }

    const onVisibilityChange = () => {
      if (document.visibilityState == 'hidden') {
        timer.pause();
        IdleTimerStore.setState({ isIdle: true });
      } else if (document.visibilityState == 'visible') {
        timer.start();
        IdleTimerStore.setState({ isIdle: false });
      }
    };

    document.addEventListener(visibilityChangeEvent, onVisibilityChange);

    return () =>
      document.removeEventListener(visibilityChangeEvent, onVisibilityChange);
  }, [timer]);

  const isDebug = useIsDebug();

  if (!isDebug) {
    return null;
  }

  return <IdleTimerDebug />;
};

IdleTimerStoreSync.defaultProps = { timeout: 15_000, startOnMount: true };

const IdleTimerDebug: FC = () => {
  const isIdle = useIdleTimerStore((x) => x.isIdle);
  return (
    <KomoDebugPortal>
      <Stack gap={'xs'}>
        <Title order={5}>Idle store</Title>
        <div>isIdle: {isIdle.toString()}</div>
      </Stack>
    </KomoDebugPortal>
  );
};
