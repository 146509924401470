import { ForwardedRef, forwardRef } from 'react';

import { OptionModel } from '@/common/models/OptionModel';

import {
  FormInputWrapper,
  IAmFormInputRenderProps
} from '../../InputWrapper/FormInputWrapper';
import { MultiSelectProps, MultiSelectV2 } from '../_Components/MultiSelect';

export interface FormMultiSelectProps<TOption extends OptionModel = OptionModel>
  extends Omit<
      MultiSelectProps<TOption>,
      'labelProps' | 'descriptionProps' | 'withAsterisk'
    >,
    IAmFormInputRenderProps<HTMLInputElement> {}

function _FormMultiSelect<T extends OptionModel = OptionModel>(
  {
    required,
    onFocus,
    selectAllTextOnFocus,
    autoFocus,
    descriptionProps,
    inputContainer,
    showSkeleton,
    labelProps,
    error,
    hideErrorMessage,
    size,
    ...rest
  }: FormMultiSelectProps<T>,
  forwardedRef: ForwardedRef<HTMLInputElement>
) {
  return (
    <FormInputWrapper<HTMLInputElement>
      ref={forwardedRef}
      autoFocus={autoFocus}
      onFocus={onFocus}
      selectAllTextOnFocus={selectAllTextOnFocus}
      error={error}
      hideErrorMessage={hideErrorMessage}
      showSkeleton={showSkeleton}
      descriptionProps={descriptionProps}
      inputContainer={inputContainer}
      labelProps={labelProps}
      size={size}
    >
      {(p, ref) => (
        <MultiSelectV2
          ref={ref}
          withAsterisk={required}
          {...rest}
          {...p}
          size={size}
        />
      )}
    </FormInputWrapper>
  );
}

export const FormMultiSelect = forwardRef(_FormMultiSelect) as <
  T extends OptionModel = OptionModel
>(
  props: FormMultiSelectProps<T> & { ref?: ForwardedRef<HTMLInputElement> }
) => ReturnType<typeof _FormMultiSelect>;
