import { FC } from 'react';

import { Group } from '@/common/components/Display/Group';
import { Text } from '@/common/components/Typography/Text';
import { useTheme } from '@/theme';

export const BaseDraftBanner: FC = () => {
  const theme = useTheme();

  return (
    <Group
      align="center"
      h={'60px'}
      style={{ backgroundColor: theme.black }}
      position="center"
      px={'1rem'}
    >
      <Group>
        <a
          href="https://www.komo.tech/?utm_source=draft-hub&utm_medium=web&utm_campaign=logo-click"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src="/assets/images/komo-own-the-moment-logo.svg"
            style={{ maxHeight: '25px' }}
          />
        </a>
        <Text color={theme.white} fw={500} fs={'italic'}>
          Draft mode
        </Text>
      </Group>
    </Group>
  );
};
