import { Guid } from '@/common/models/Guid';
import { SiteProperties } from '@/common/models/site/SiteProperties';
import { mapArray } from '@/common/utils/ArrayFunctions';
import { utcDateOrNow } from '@/common/utils/DateFunctions';

import { PageCard } from './PageCard';
import { PageProperties } from './shared/PageProperties';
import { PageStatuses } from './shared/PageStatuses';

export class Page {
  id: Guid;
  siteId: Guid;
  siteMenuId?: Guid;
  cards: PageCard[];
  name: string;
  slug: string;
  status: PageStatuses;
  isDefault: boolean;
  updatedAt: Date;
  properties: PageProperties;

  constructor(props?: Partial<Page>) {
    props = props || {};
    Object.assign(this, props);
    this.id = Guid.valueOrNew(props.id);
    this.siteId = Guid.valueOrUndefined(props.siteId);
    this.siteMenuId = Guid.valueOrUndefined(props.siteMenuId);
    this.cards = mapArray(props.cards, (x) => new PageCard(x));
    this.updatedAt = utcDateOrNow(props.updatedAt);
    this.properties = new PageProperties({
      HeaderEnabled: true,
      BillboardEnabled: true,
      FooterEnabled: true,
      HeaderLogoEnabled: true,
      FooterLogoEnabled: true,
      IsGoogleIndexed: true,
      SocialNetworkEnabled: true,
      ...props.properties
    });
  }

  get isDraft() {
    return this.status === PageStatuses.Draft;
  }

  getMetaValues(site: { name: string; properties: SiteProperties }) {
    const title = this.isDefault
      ? site.properties.MetaTitle
      : this.properties.MetaTitle;
    const description = this.isDefault
      ? site.properties.MetaDescription
      : this.properties.MetaDescription;
    const index = this.isDefault
      ? site.properties.IsGoogleIndexed
      : this.properties.IsGoogleIndexed;
    const image = this.isDefault
      ? site.properties.MetaImageModel
      : this.properties.MetaImageModel;

    const sameNames = this.name === site.name;

    return {
      metaTitle:
        title || (sameNames ? this.name : `${this.name} | ${site.name}`),
      metaDescription: description,
      metaIcon: site.properties.MetaIconImage,
      metaImage: image,
      metaIndex: index,
      metaGoogleSiteVerification: site.properties.GoogleSiteVerificationCode
    };
  }
}
