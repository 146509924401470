import { FC } from 'react';

import { CardHeader } from '@/common/components/Card/shared/CardHeader';
import { Stack } from '@/common/components/Display/Stack';
import { Text } from '@/common/components/Typography/Text';

import classes from './DynamicFormRenderer.module.css';
import { DynamicFormRendererHeaderProps } from './DynamicFormRendererTypes';

export const DynamicFormRendererHeader: FC<DynamicFormRendererHeaderProps> = ({
  title,
  subTitle,
  ta = 'center',
  spacing
}) => {
  const hasSubTitle = !!subTitle;
  const hasTitle = !!title;

  if (!hasTitle && !hasSubTitle) {
    return <></>;
  }

  return (
    <Stack gap={spacing}>
      {hasTitle && (
        <CardHeader variant="h3" ta={ta} className={classes.headerText}>
          {title}
        </CardHeader>
      )}
      {hasSubTitle && (
        <Text my={0} ta={ta} className={classes.headerText}>
          {subTitle}
        </Text>
      )}
    </Stack>
  );
};
