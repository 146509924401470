import { FCC } from 'fcc';
import { forwardRef, ReactNode } from 'react';

import { Box, BoxProps } from '@/common/components/Display/Box';

import classes from './FormGroup.module.css';

interface Props extends BoxProps {
  inline?: boolean;
  fullWidth?: boolean;
  disabled?: boolean;
  id?: string;
  children?: ReactNode;
}

export const FormGroup = forwardRef<HTMLDivElement, Props>(
  ({ children, display, inline, fullWidth, style, ...rest }, ref) => {
    return (
      <Box
        ref={ref}
        display={display || 'flex'}
        style={{
          flexDirection: inline ? 'row' : 'column',
          boxSizing: 'border-box',
          ...style
        }}
        w={fullWidth ? '100%' : undefined}
        pos={'relative'}
        mb={'1rem'}
        {...rest}
      >
        {children}
      </Box>
    );
  }
);

export const StyledFormCheckGroup: FCC<Props> = ({ children, disabled }) => {
  return (
    <FormGroup
      opacity={disabled ? 0.5 : 1}
      mb={'0.5rem'}
      className={classes.checkboxGroup}
    >
      {children}
    </FormGroup>
  );
};
