import { FCC } from 'fcc';
import { MouseEvent } from 'react';

import { Box, BoxProps } from '@/common/components/Display/Box';

import classes from './ListItemAction.module.css';

interface Props extends BoxProps {
  onClick?: (e: MouseEvent) => void;
}

export const ListItemAction: FCC<Props> = ({ children, onClick, ...rest }) => {
  return (
    <Box
      data-has-click-event={!!onClick}
      onClick={onClick}
      className={classes.root}
      {...rest}
    >
      {children}
    </Box>
  );
};
ListItemAction.displayName = 'ListItemAction';
