import { Input as MantineInput } from '@mantine/core';
import { FC, useState } from 'react';
import { FieldValues } from 'react-hook-form';

import { Button } from '@/common/components/Button';
import { Group } from '@/common/components/Display/Group';
import { CorrectIconFilled } from '@/common/components/Icons/CorrectIconFilled';
import { QrCodeReaderDrawer } from '@/common/components/QrCode/Reader/Drawer';
import { Text } from '@/common/components/Typography/Text';
import { useActionHandler } from '@/common/hooks/useActionHandler';
import { useFlag } from '@/common/hooks/useFlag';
import { useOnMount } from '@/common/hooks/useOnMount';

import { IAmFormHookField } from '../../FormHookField';
import { FormInputWrapper } from '../../InputWrapper';
import {
  DynamicFormContextData,
  IAmDynamicFormFieldProps,
  QrCodeDynamicFieldOptions
} from '../types';
import { DynamicFormHookField } from './__DynamicFormHookField';

export function DynamicFormQrCodeField<
  TFieldValues extends FieldValues = FieldValues,
  TContextData extends DynamicFormContextData = DynamicFormContextData
>({
  field,
  disabled,
  contextData,
  ...rest
}: IAmDynamicFormFieldProps<TFieldValues, TContextData>) {
  return (
    <DynamicFormHookField<TFieldValues, TContextData>
      field={field}
      disabled={disabled}
      contextData={contextData}
    >
      {(h) => <_Field hook={h} field={field} {...rest} />}
    </DynamicFormHookField>
  );
}

interface _FieldProps
  extends IAmFormHookField,
    Pick<IAmDynamicFormFieldProps, 'field' | 'size' | 'id'> {}

const _Field: FC<_FieldProps> = ({ field, hook, id, size }) => {
  const { onCheckAsync, defaultIsValid, ...inputOptions } = field.data
    .options as QrCodeDynamicFieldOptions;

  const [isValid, setIsValid] = useState(defaultIsValid && !!hook.field.value);
  const drawerOpen = useFlag();
  const [handleAsync, { isHandling }] = useActionHandler();

  const handleCheckAsync = (value: string) => {
    return handleAsync(() => onCheckAsync(value), {
      onSuccess: (r) => {
        if (r?.success) {
          setIsValid(true);
          hook.setFieldValue(r.data.code);
        } else {
          hook.setFieldValue('');
          setIsValid(false);
        }
      }
    });
  };

  useOnMount(() => {
    if (!hook.field.value) {
      return;
    }

    const defaultValue = hook.field.value;
    hook.setFieldValue('');
    handleCheckAsync(defaultValue);
  });

  return (
    <>
      <FormInputWrapper<HTMLButtonElement> ref={hook.field.ref} size={size}>
        {(p, r) => (
          <MantineInput.Wrapper
            withAsterisk={hook.required}
            {...inputOptions}
            {...(p as any)}
            id={id}
          >
            {isValid ? (
              <Group align="center" gap="0.5rem">
                <CorrectIconFilled color="var(--mantine-color-green-9)" />
                <Text size="sm">Verified</Text>
              </Group>
            ) : (
              <Button
                display="block"
                variant="default"
                w="100%"
                id={id}
                busy={isHandling}
                disabled={hook.disabled}
                size={size}
                style={
                  !!hook.showError
                    ? {
                        borderColor: 'var(--mantine-color-red-7)',
                        color: 'var(--mantine-color-red-7)'
                      }
                    : undefined
                }
                onClick={drawerOpen.setTrue}
                ref={r}
              >
                Scan QR Code
              </Button>
            )}
          </MantineInput.Wrapper>
        )}
      </FormInputWrapper>
      <QrCodeReaderDrawer
        isValid={isValid}
        onCheckAsync={handleCheckAsync}
        onClose={drawerOpen.setFalse}
        opened={drawerOpen.value}
        title="Scan"
        infoContent="Point your camera at the QR code to scan"
        onIsValidCallback={drawerOpen.setFalse}
      />
    </>
  );
};
