import isNil from 'lodash/isNil';

import { IAmFormHookField } from '../../FormHookField';
import {
  FormCountrySelect,
  FormCountrySelectProps
} from './FormCountrySelectV2';

export interface FormHookCountrySelectProps
  extends IAmFormHookField,
    Omit<
      FormCountrySelectProps,
      'required' | 'name' | 'value' | 'defaultValue' | 'error' | 'disabled'
    > {}

export const FormHookCountrySelect = (props: FormHookCountrySelectProps) => {
  const { hook, onBlur, onChange, ...rest } = props;
  return (
    <FormCountrySelect
      {...rest}
      required={hook.required}
      name={hook.field.name}
      ref={hook.field.ref}
      disabled={hook.disabled}
      onBlur={(e) => {
        hook.field.onBlur();
        onBlur?.(e);
      }}
      onChange={(e, o) => {
        hook.field.onChange(e);
        onChange?.(e, o);
      }}
      value={!isNil(hook.field.value) ? hook.field.value : ''}
      error={hook.showError ? hook.errorMessage : undefined}
    />
  );
};
