import { MantineSize } from '@mantine/core';
import { FC } from 'react';

import { Button, ButtonProps } from '@/common/components/Button';
import { Stack, StackProps } from '@/common/components/Display/Stack';
import { Guid } from '@/common/models/Guid';

import {
  DynamicFormRendererButtonProps,
  DynamicFormRendererExtraButtonProps
} from './DynamicFormRendererTypes';

interface Props extends Pick<StackProps, 'gap'> {
  formId: Guid;
  submitButton?: DynamicFormRendererButtonProps;
  extraButtons?: DynamicFormRendererExtraButtonProps[];
  isSubmitting?: boolean;
  disabled?: boolean;
  size?: MantineSize;
  submitButtonHidden?: boolean;
  submitButtonDisabled?: boolean;
}

export const DynamicFormRendererButtons: FC<Props> = ({
  submitButton,
  extraButtons,
  formId,
  gap = 'md',
  isSubmitting = false,
  disabled = false,
  submitButtonHidden = false,
  submitButtonDisabled = false,
  size
}) => {
  const hasExtraButtons = !!extraButtons?.length;
  const hasSubmitButton = !submitButtonHidden;

  if (!hasExtraButtons && !hasSubmitButton) {
    return null;
  }

  return (
    <Stack gap={gap}>
      {hasSubmitButton ? (
        <DynamicFormRendererButton
          type="submit"
          id={`${formId}-submit`}
          {...submitButton}
          busy={isSubmitting}
          disabled={disabled || submitButtonDisabled}
          size={size}
          text={submitButton?.text ?? 'Submit'}
        />
      ) : null}

      {hasExtraButtons
        ? extraButtons.map((b, index) => (
            <DynamicFormRendererButton
              key={index}
              {...b}
              disabled={disabled || isSubmitting}
            />
          ))
        : null}
    </Stack>
  );
};

interface DynamicFormRendererButtonElementProps
  extends DynamicFormRendererButtonProps,
    Pick<
      ButtonProps,
      | 'disabled'
      | 'size'
      | 'busy'
      | 'type'
      | 'onClick'
      | 'rightSection'
      | 'style'
      | 'id'
    > {}

export const DynamicFormRendererButton: FC<
  DynamicFormRendererButtonElementProps
> = ({ text, bgColor, borderColor, color, ...rest }) => {
  return (
    <Button
      variant="styled"
      overflowHidden
      styledVariantProps={{
        color: color,
        bgColor: bgColor,
        borderColor: borderColor
      }}
      {...rest}
    >
      {text}
    </Button>
  );
};
