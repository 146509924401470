import { MantineSize } from '@mantine/core';
import { useMutation } from '@tanstack/react-query';
import { FC, useEffect, useRef, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';

import { Stack } from '@/common/components/Display/Stack';
import { FormHook } from '@/common/components/Form/Form';
import { FormHookField } from '@/common/components/Form/FormHookField';
import { FormHookEmailInput } from '@/common/components/Form/FormTextInput/FormHookEmailInput';
import { emailValidator } from '@/common/components/Form/FormValidators/_email';
import { useToaster } from '@/common/components/Toast';
import { Text } from '@/common/components/Typography/Text';
import { useEvent } from '@/common/hooks/useEvent';
import { Guid } from '@/common/models/Guid';
import { PublicAuthService } from '@/front/data/Auth';

import {
  AuthButton,
  AuthButtonGroup,
  AuthButtonProps
} from '../Shared/AuthButton';

interface FormValues {
  email: string;
}
interface Props {
  siteId: Guid;
  initialValues?: Partial<FormValues>;
  emailDisabled?: boolean;
  descriptionText?: string;
  submitButton: AuthButtonProps;
  onSuccess: (email: string) => void;
  extraButtons?: AuthButtonProps[];
  returnUrl?: string;
  size?: MantineSize;
}

const defaultError =
  'An error occurred sending the reset link. Please try again';

export const ForgotPasswordForm: FC<Props> = ({
  siteId,
  initialValues = {},
  emailDisabled,
  descriptionText,
  onSuccess,
  submitButton,
  extraButtons,
  returnUrl,
  size
}) => {
  const toaster = useToaster();
  const [emailError, setEmailError] = useState<string>(undefined);
  const formRef = useRef<UseFormReturn<FormValues>>();

  const { mutate: handleResetPasswordAsync, isLoading } = useMutation(
    async (values: FormValues) => {
      const response = await PublicAuthService.actions.forgotPasswordAsync({
        email: values.email,
        siteId: siteId.toString(),
        returnUrl: returnUrl
      });
      return {
        email: values.email,
        ...response
      };
    },
    {
      onSuccess: ({ email, success, errorMessage }) => {
        if (!success) {
          toaster.error(errorMessage || defaultError);
          setEmailError(errorMessage || defaultError);
        } else {
          onSuccess(email);
        }
      },
      onError: (err) => {
        console.error(err);
        toaster.error(defaultError);
        setEmailError(defaultError);
      }
    }
  );

  useEffect(() => {
    if (emailError) {
      formRef.current.trigger('email', { shouldFocus: true });
    }
  }, [emailError]);

  //Clear the email error if any
  const handleInputChange = useEvent(() => {
    if (emailError) {
      setEmailError(undefined);
    }
  });

  const hasDescription = !!descriptionText;
  return (
    <FormHook<FormValues>
      defaultValues={{
        email: '',
        ...(initialValues || {})
      }}
      onSubmit={handleResetPasswordAsync}
      onInit={(f) => (formRef.current = f)}
    >
      {hasDescription && <Text>{descriptionText}</Text>}
      <FormHookField<FormValues>
        name="email"
        required={{ enabled: true }}
        disabled={emailDisabled || isLoading}
        contextData={emailError}
        validators={[emailValidator(), () => emailError]}
      >
        {(h) => (
          <FormHookEmailInput
            hook={h}
            withIcon
            size={size}
            label="Email"
            onChange={handleInputChange}
          />
        )}
      </FormHookField>
      <Stack>
        <AuthButton
          busy={isLoading}
          type="submit"
          size={size}
          {...submitButton}
        />
        <AuthButtonGroup
          buttons={extraButtons}
          disabled={isLoading}
          size={size}
        />
      </Stack>
    </FormHook>
  );
};
