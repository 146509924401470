import { useMediaQuery } from '@mantine/hooks';
import { useRouter } from 'next/router';
import { FC, useEffect, useMemo, useState } from 'react';

import { SiteAuthState } from '@/common/components/Card/shared/Front/Routing/AuthSiteUrlFunctions';
import { SiteBadgeStateParameterName } from '@/common/components/Card/shared/Front/Routing/DrawerRouting';
import { Box } from '@/common/components/Display/Box';
import { Group } from '@/common/components/Display/Group';
import { Space } from '@/common/components/Space';
import { useMeasure } from '@/common/hooks/use-measure';
import { useFlag } from '@/common/hooks/useFlag';
import { asBoolean } from '@/common/utils/BooleanFunctions';
import { ThemeConstants } from '@/common/utils/ThemeConstants';
import {
  AuthEventViewTypes,
  PublicEventService
} from '@/front/data/PublicEventService';
import { PublicSiteMenuService } from '@/front/data/SiteMenus/PublicSiteMenuService';
import { useKomoNavColorVars } from '@/front/hooks/useKomoNavColorVars';
import { oAuthSignIn } from '@/front/utils/OAuthSignIn';
import { useTheme } from '@/theme';

import { useUser } from '../../UserContext';
import { useFrontPage, useFrontSite } from '../Store';
import { AuthDrawer } from './AuthDrawer';
import { HeaderAuthButton } from './Buttons';
import { HeaderBurgerMenu } from './Buttons/HeaderBurgerMenu';
import { MenuGroup } from './MenuGroup/MenuGroup';
import { HeaderBadgesButton } from './Profile';
import classes from './SiteHeader.module.css';
import { SiteHeaderImage } from './SiteHeaderImage';
import { useSiteAuthRouting } from './useSiteAuthRouting';

interface State {
  route: SiteAuthState;
  returnUrl?: string;
  email?: string;
}

interface Props {
  hideMenu?: boolean;
}

export const SiteHeader: FC<Props> = ({ hideMenu }) => {
  const site = useFrontSite((x) => x.site);
  const page = useFrontPage((x) => x.page);
  const nextRouter = useRouter();
  //Holds the state of the site auth. Should only be updated by a route change
  const [state, setState] = useState<State>({
    route: SiteAuthState.None
  });
  const badgeDrawerOpen = useFlag(false);
  const authDrawerOpen = useFlag(false);

  const [siteMenu, siteMenuLoading] = PublicSiteMenuService.hooks.useGet(
    page?.siteMenuId,
    { enabled: !hideMenu && !!page?.siteMenuId }
  );

  const user = useUser();
  const router = useSiteAuthRouting({
    onStateChange: (route, { returnUrl, email }) =>
      setState({ route, returnUrl, email })
  });

  const badgeDrawerStateQuery =
    nextRouter.query[SiteBadgeStateParameterName] || '';

  const badgeDrawerState = asBoolean(
    typeof badgeDrawerStateQuery == 'string'
      ? badgeDrawerStateQuery
      : badgeDrawerStateQuery[0]
  );

  useEffect(() => {
    if (badgeDrawerState) {
      badgeDrawerOpen.setTrue();
    } else {
      badgeDrawerOpen.setFalse();
    }
  }, [badgeDrawerState]);

  //Manage state changes
  useEffect(() => {
    switch (state.route) {
      case SiteAuthState.SignUp:
      case SiteAuthState.SignUpConfirm:
      case SiteAuthState.SignIn:
      case SiteAuthState.ForgotPassword:
      case SiteAuthState.Verify:
        if (user.isAnonymous) {
          authDrawerOpen.setTrue();
        } else {
          router.replace(SiteAuthState.Profile);
        }
        break;
      case SiteAuthState.Profile:
        if (!user.isAnonymous) {
          authDrawerOpen.setTrue();
        } else {
          router.replace(SiteAuthState.None);
        }
        break;
      case SiteAuthState.None:
        authDrawerOpen.setFalse();
        break;
      default:
        const unsupported: never = state.route;
        console.error('Unsupported card type', unsupported);
        break;
    }
  }, [state.route]);

  const tryTrackAuthEvent = (state: SiteAuthState) => {
    let type: AuthEventViewTypes = AuthEventViewTypes.Unknown;
    switch (state) {
      case SiteAuthState.ForgotPassword:
        type = AuthEventViewTypes.ForgotPasswordForm;
        break;
      case SiteAuthState.SignIn:
      case SiteAuthState.Verify:
        type = AuthEventViewTypes.SignInForm;
        break;
      case SiteAuthState.SignUp:
        type = AuthEventViewTypes.SignUpForm;
        break;
    }
    if (type != AuthEventViewTypes.Unknown) {
      PublicEventService.trackAuthViewedAsync(site, {
        source: 'Site',
        type,
        isContactVerification: state === SiteAuthState.Verify ? true : undefined
      }).catch((e) => console.error('Failed to track auth event', type, e));
    }
  };

  const handleStateChange = (state: SiteAuthState) => {
    if (site.properties.AuthSsoOAuthEnabled && state === SiteAuthState.SignIn) {
      oAuthSignIn(nextRouter);
    } else {
      tryTrackAuthEvent(state);
      router.push(state);
    }
  };

  const {
    authEnabled,
    headerImage,
    showVerifySignIn,
    ProfileDrawerEnabled,
    HeaderLogoHeight,
    HeaderEnabled,
    HeaderBackgroundColor,
    HeaderTextColor
  } = useMemo(() => {
    const {
      AuthEnabled,
      AuthUserAccountsHideHeaderSignInButton,
      AuthSsoOAuthEnabled,
      ContactVerifyShowSignInButton,
      ProfileDrawerEnabled
    } = site.properties;

    const properties = page?.properties ?? site.properties;
    const {
      HeaderLogoEnabled,
      BrandImage,
      HeaderLogoHeight,
      HeaderEnabled,
      HeaderBackgroundColor,
      HeaderTextColor
    } = properties;

    const headerImage = !HeaderLogoEnabled ? undefined : BrandImage;

    const authEnabled =
      AuthSsoOAuthEnabled ||
      (AuthEnabled && !AuthUserAccountsHideHeaderSignInButton);

    return {
      authEnabled,
      headerImage,
      showVerifySignIn: ContactVerifyShowSignInButton && !authEnabled,
      ProfileDrawerEnabled,
      HeaderLogoHeight,
      HeaderEnabled,
      HeaderBackgroundColor,
      HeaderTextColor
    };
  }, [site, page]);

  const navVars = useKomoNavColorVars({
    backgroundColor: HeaderBackgroundColor,
    textColor: HeaderTextColor,
    textActiveColor: page?.properties.HeaderActiveTextColor
  });

  const theme = useTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints['sm']})`);
  const menuOverflowed = useFlag();
  const [fullHeaderWidth, setFullHeaderWidth] = useState<number>();
  const headerWrapper = useMeasure({
    onChange: (element, data) => {
      if (!isMobile) {
        if (data.hasBounds && element.scrollWidth > element.clientWidth) {
          setFullHeaderWidth(element.scrollWidth);
          menuOverflowed.setTrue();
        } else if (fullHeaderWidth && element.clientWidth > fullHeaderWidth) {
          menuOverflowed.setFalse();
        }
      }
    }
  });

  // force a remeasure when the site menu is loaded to check for overflow
  useEffect(() => {
    if (!isMobile && !menuOverflowed.value && !siteMenuLoading) {
      headerWrapper.reMeasure();
    }
  }, [siteMenuLoading]);

  if (!HeaderEnabled) {
    return null;
  }

  return (
    <>
      <Box
        ref={headerWrapper.register}
        __vars={{
          '--site-header-box-shadow': ThemeConstants.KomoCardShellBoxShadow,
          ...navVars
        }}
        className={classes.wrapper}
      >
        <Group
          justify="space-between"
          gap="xs"
          className={classes.content}
          maw={
            !siteMenuLoading && siteMenu
              ? undefined
              : theme.other.cardContainerSize
          }
        >
          {(isMobile || menuOverflowed.value) &&
            (!siteMenuLoading && siteMenu ? (
              <HeaderBurgerMenu menu={siteMenu} />
            ) : (
              <Space w={50} />
            ))}
          {headerImage ? (
            <SiteHeaderImage height={HeaderLogoHeight} image={headerImage} />
          ) : undefined}
          {!isMobile &&
            !menuOverflowed.value &&
            !siteMenuLoading &&
            siteMenu && <MenuGroup menu={siteMenu} />}
          {authEnabled || ProfileDrawerEnabled || showVerifySignIn ? (
            <Group align="center" gap="0.5rem">
              {ProfileDrawerEnabled ? (
                <HeaderBadgesButton
                  siteId={site.id}
                  badgeDrawerOpen={badgeDrawerOpen.value}
                />
              ) : undefined}
              {authEnabled || showVerifySignIn ? (
                <HeaderAuthButton
                  site={site}
                  onStateChange={handleStateChange}
                  user={user}
                />
              ) : undefined}
            </Group>
          ) : (
            <Space w={50} />
          )}
        </Group>
      </Box>
      {authEnabled || showVerifySignIn ? (
        <AuthDrawer
          open={authDrawerOpen.value}
          state={state.route}
          returnUrl={state.returnUrl}
          onStateChange={handleStateChange}
          email={state.email}
        />
      ) : undefined}
    </>
  );
};
